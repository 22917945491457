import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const About = ({ article, articleTimeout, close }) => {
    const data = useStaticQuery(graphql`
    query {
        company: file(relativePath: { eq: "tobe-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bona: file(relativePath: { eq: "bona_gabor_tall.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          kator: file(relativePath: { eq: "kator_balint_tall.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
      }
  `);

    const { locale } = useIntl();

    return (
        <article
            id="about"
            className={`${article === 'about' ? 'active' : ''} ${articleTimeout ? 'timeout' : ''}`}
            style={{ display: 'none', textAlign: 'justify' }}
        >
            <h2 className="major"><FormattedMessage id="menu.about" /></h2>

            <>
                <div className="contact-container">
                    <figure>
                        <Img fluid={data.company.childImageSharp.fluid} />
                    </figure>
                    <div className={"text-container"}>
                        <h3><FormattedMessage id="about.company.title" /></h3>
                        <p>
                            <FormattedMessage id="about.company.p1" />
                        </p>
                    </div>
                </div>
                <p>
                    <FormattedMessage id="about.company.p2" />
                </p>
            </>

            <>
                <div className="contact-container">
                    <div className={"text-container"}>
                        <h3><FormattedMessage id="about.gabor.title" /></h3>
                        <p>
                            <FormattedMessage id="about.gabor.p1" />
                        </p>
                    </div>
                    <figure className="right">
                        <Img fluid={data.bona.childImageSharp.fluid} />
                    </figure>
                </div>
                <p>
                    <FormattedMessage id="about.gabor.p2" />
                </p>
                <p>
                    <FormattedMessage id="about.gabor.p3" />
                </p>
                {
                    locale === 'en' &&
                    <p>
                        <FormattedMessage id="about.gabor.languages" />
                    </p>
                }

            </>

            <>
                <div className="contact-container">
                    <figure>
                        <Img fluid={data.kator.childImageSharp.fluid} />
                    </figure>
                    <div className={"text-container"}>
                        <h3><FormattedMessage id="about.balint.title" /></h3>
                        <p>
                            <FormattedMessage id="about.balint.p1" />
                        </p>
                    </div>
                </div>
                <p>
                    <FormattedMessage id="about.balint.p2" />
                </p>
                <p>
                    <FormattedMessage id="about.balint.p3" />
                </p>
                {
                    locale === 'en' &&
                    <p>
                        <FormattedMessage id="about.balint.languages" />
                    </p>
                }
            </>

            {close}
        </article>
    )
};

About.propTypes = {
    article: PropTypes.string,
    articleTimout: PropTypes.bool,
    close: PropTypes.element
}

export default About
