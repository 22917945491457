const IMAGES = [
    {
        name: 'Első',
        image: 'p1.jpg',
        description: 'Teszt'
    },
    {
        name: 'Második',
        image: 'p2.jpg',
        description: 'Körtefa'
    },
    {
        name: 'Harmadik',
        image: 'p3.jpg',
        description: 'Almalé'
    },
    {
        name: 'Negyedik',
        image: 'p4.jpg',
        description: 'Kutya'
    },
    {
        name: 'Ötödik',
        image: 'p1.jpg',
        description: 'Cica'
    },
    {
        name: 'Hatodik',
        image: 'p2.jpg',
        description: 'Bakkecske'
    },
];

export default IMAGES;