import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';

const Header = (props) => {
    return (
    <header id="header" style={props.timeout ? { display: 'none' } : {}}  locale={props.locale}>
        <div className="logo">
            <Logo />    
        </div>
        <div className="content">
            <div className="inner">
                <h1>ToBe Art Productions</h1>
                <p><FormattedMessage id="main.line1"/> <br />
                </p>
            </div>
        </div>
        <nav>
            <ul>
                <li><button onClick={() => { props.onOpenArticle('about') }}><FormattedMessage id="menu.about"/></button></li>
                <li><button onClick={() => { props.onOpenArticle('productions') }}><FormattedMessage id="menu.productions"/></button></li>
                <li><button onClick={() => { props.onOpenArticle('gallery') }}><FormattedMessage id="menu.gallery"/></button></li>
                <li><button onClick={() => { props.onOpenArticle('events') }}><FormattedMessage id="menu.events"/></button></li>
                <li><button onClick={() => { props.onOpenArticle('contact') }}><FormattedMessage id="menu.contact"/></button></li>
            </ul>
        </nav>
    </header>
    )
}

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
