import React, { memo } from "react";
import { cold } from 'react-hot-loader';
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby';
import IMAGES from '../constants/images';
import Gallery from 'react-grid-gallery';
import { FormattedMessage } from 'react-intl';

const Images = ({ article, articleTimeout, close }) => {
  const data = useStaticQuery(graphql`
    query {
        allFile(filter: {
          extension: {regex: "/(jpg)|(png)|(jpeg)/"}, 
        }){
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid
                  },
                  fixed(width: 180, height: 180) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
      }
  `);

  const galleryImages = IMAGES.map(({ name, image, description }) => {
    const img = data.allFile.edges.find(
      ({ node }) => node.relativePath === image
    ).node;

    const { src, base64, srcSet } = img.childImageSharp.fluid;
    const { src: thumbnail, height: thumbnailHeight, width: thumbnailWidth } = img.childImageSharp.fixed;

    return {
      src,
      srcSet,
      thumbnail,
      nano: base64,
      caption: description,
      alt: description,
      thumbnailWidth,
      thumbnailHeight,
    };
  });

  return (
    <article
      id="gallery"
      className={`${article === 'gallery' ? 'active' : ''} ${articleTimeout ? 'timeout' : ''}`}
      style={{ display: 'none' }}>
      <h2 className="major"><FormattedMessage id="menu.gallery" /></h2>
      {/* <Gallery images={galleryImages} backdropClosesModal={true} enableImageSelection={false} /> */}
      <h3><FormattedMessage id="coming_soon" /></h3>
      {close}
    </article>
  )
};

Images.propTypes = {
  article: PropTypes.string,
  articleTimout: PropTypes.bool,
  close: PropTypes.element
}

export default memo(cold(Images));
