import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { ReactComponent as Hun } from '../assets/svg/hungary.svg';
import { ReactComponent as Eng } from '../assets/svg/united-kingdom.svg';

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
        <p className="footer-text">&copy; ToBe Art Kft.  e-mail: <a href="mailto:production@tobeart.hu">production@tobeart.hu</a> </p>
        <p className="footer-text">Design: dlac03 & <a href="https://html5up.net">HTML5 UP</a>.</p>
        <p className="footer-text language-selector">
            <span className="icon-container"><Hun/></span><Link to={"/"}>hu</Link>
            <span className="icon-container"><Eng/></span><Link to={"/en"}> en</Link>
        </p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer