import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { email as emailRegExp } from '../constants/regexp';
import cogoToast from 'cogo-toast';
import { FormattedMessage, injectIntl } from 'react-intl';

const formName = 'contact';

const Contact = ({ article, articleTimeout, close, onCloseArticle, intl }) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const setValue = (e) => {
        const value = e.target.value;
        switch (e.target.id) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    };

    const reset = () => {
        setName('');
        setEmail('');
        setMessage('');
    }

    const submit = (e) => {
        e.preventDefault();
        const form = { name, email, message, 'form-name': formName };
        const formBody = Object.keys(form).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(form[key])}`).join('&');
        if (isFormValid(form)) {
            fetch('/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            });
            reset();
            cogoToast.success("Üzenet elküldve!");
            setTimeout(() => {
                onCloseArticle();
            }, 1000);
        } else {
            cogoToast.warn("Hibás kitöltés!");
        }
    };

    const isFormValid = ({ name, email, message }) => {
        return name && name.length > 2 && message && message.length > 2 && emailRegExp.test(email);
    };

    const clearLabel = intl.formatMessage({id: 'contact.clear'});
    const submitLabel = intl.formatMessage({id: 'contact.submit'});

    return (
        <article
            id="contact"
            className={`${article === 'contact' ? 'active' : ''} ${
                articleTimeout ? 'timeout' : ''
                }`}
            style={{ display: 'none' }}
        >
            <h2 className="major"><FormattedMessage id="menu.contact" /></h2>
            <p><FormattedMessage id="contact.firstLine" /><a href="mailto:production@tobeart.hu">production@tobeart.hu</a> <FormattedMessage id="contact.secondLine" /> </p>
            <form method="post" data-netlify="true" netlify-honeypot="field" onSubmit={submit} encType="application/x-www-form-urlencoded" name={formName}>
                <div className="field half first">
                    <label htmlFor="name"><FormattedMessage id="contact.name" />*</label>
                    <input type="text" name="name" id="name" value={name} onChange={setValue} required={true} />
                </div>
                <div className="field half">
                    <label htmlFor="email">Email*</label>
                    <input type="text" name="email" id="email" value={email} onChange={setValue} required={true} />
                </div>
                <div className="field">
                    <label htmlFor="message"><FormattedMessage id="contact.message" />*</label>
                    <textarea name="message" id="message" rows="4" value={message} onChange={setValue} required={true}></textarea>
                </div>
                <div className="field">
                    <label htmlFor="message"><FormattedMessage id="contact.required" /></label>
                </div>
                <ul className="actions">
                    <li>
                        <input type="submit" value={submitLabel} className="special" />
                    </li>
                    <li>
                        <input type="reset" value={clearLabel} onClick={reset} />
                    </li>
                </ul>
            </form>
            <ul className="icons">
                <li>
                    <a href="https://facebook.com/tobeartproductions" target="_blank" className="icon fa-facebook">
                        <span className="label">Facebook</span>
                    </a>
                </li>
                <li>
                    <a href="https://instagram.com/tobeartproductions" target="_blank" className="icon fa-instagram">
                        <span className="label">Instagram</span>
                    </a>
                </li>
            </ul> 
            {close}
        </article>
    )
};

Contact.propTypes = {
    article: PropTypes.string,
    articleTimout: PropTypes.bool,
    close: PropTypes.element
}

export default injectIntl(Contact)
