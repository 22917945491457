import React from 'react';
import PropTypes from 'prop-types';
import { events } from '../constants/events';
import { EventList } from '../components/EventList';
import { FormattedMessage } from 'react-intl';

const Events = ({ article, articleTimeout, close }) => {

    return (
        <article
            id="events"
            className={`${article === 'events' ? 'active' : ''} ${articleTimeout ? 'timeout' : ''}`}
            style={{ display: 'none' }}
        >
            <h2 className="major"><FormattedMessage id="menu.events" /></h2>
            {/* <EventList events={events} /> */}
            <h3><FormattedMessage id="coming_soon" /></h3>
            {close}
        </article>
    );
};

Events.propTypes = {
    article: PropTypes.string,
    articleTimout: PropTypes.bool,
    close: PropTypes.element
}

export default Events
