import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Collapse } from 'react-collapse';
import { useStaticQuery, graphql } from 'gatsby'; 

const Arrow = ({ isOpen }) => (<span>{isOpen ? '▲' : '▼'}</span>);

const Productions = ({ article, articleTimeout, close }) => {
    const [disney, setDisney] = useState(false);
    const [operett, setOperett] = useState(false);
    const [dolce, setDolce] = useState(false);
    const initialStyle = { height: 0, overflow: 'hidden' };

    const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)
  const downloadLink = data.allFile.edges.length &&  data.allFile.edges.length > 0 ? data.allFile.edges[0].node.publicURL : "";

    return (
        <article
            id="work"
            className={`${article === 'productions' ? 'active' : ''} ${
                articleTimeout ? 'timeout' : ''
                }`}
            style={{ display: 'none' }}>
            <h2 className="major"><FormattedMessage id="menu.productions" /></h2>
            <div className="production" onClick={() => { setDisney(!disney) }}>
                <h3><span><FormattedMessage id="productions.disney.title" /></span><Arrow isOpen={disney} /></h3>
                <h4><FormattedMessage id="productions.disney.subtitle" /></h4>
                <Collapse isOpened={disney} initialStyle={initialStyle}>
                    <p>
                        <FormattedMessage id="productions.disney.content" />
                    </p>
                    <p>
                        <a href={downloadLink} target="_blank"><FormattedMessage id="productions.disney.download" /></a>
                    </p>
                </Collapse>
            </div>
            <div className="production" onClick={() => { setOperett(!operett) }}>
                <h3><span><FormattedMessage id="productions.operett.title" /></span><Arrow isOpen={operett} /></h3>
                <Collapse isOpened={operett} initialStyle={initialStyle}>
                    <p>
                        <FormattedMessage id="productions.operett.content" />
                    </p>
                </Collapse>
            </div>
            <div className="production" onClick={() => { setDolce(!dolce) }}>
                <h3><span><FormattedMessage id="productions.dolce.title" /></span><Arrow isOpen={dolce} /></h3>
                <h4><FormattedMessage id="productions.dolce.subtitle" /></h4>
                <Collapse isOpened={dolce} initialStyle={initialStyle}>
                    <p>
                        <FormattedMessage id="productions.dolce.content" />
                    </p>
                </Collapse>
            </div>
            <p className="production-info">
                <FormattedMessage id="productions.info" />
            </p>
            {close}
        </article>
    )
};

Productions.propTypes = {
    article: PropTypes.string,
    articleTimout: PropTypes.bool,
    close: PropTypes.element
}

export default Productions
