
import React from 'react';
import { IntlProvider } from 'react-intl';
import hu from '../i18n/hu.json';
import en from '../i18n/en.json';
import flat from 'flat';

const messages = { hu: flat(hu), en: flat(en) };

const LocalizationProvider = ({ locale, children }) => {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  )
};

export default LocalizationProvider;