import React from 'react'
import Layout from '../components/Layout'

import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'
import BackgroundSection from '../components/Background'

import { AppContext } from '../components/AppContext';
import IntlProvider from '../components/IntlProvider';

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isArticleVisible: false,
      timeout: false,
      articleTimeout: false,
      article: '',
      loading: 'is-loading',
      currentImage: null,
      setCurrentImage: this.setCurrentImage
    }
  }

  setCurrentImage = (currentImage) => {
    this.setState({ currentImage });
  };

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' });
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleOpenArticle = (article) => {
    this.setState({
      isArticleVisible: !this.state.isArticleVisible,
      article
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
    }, 350)

    window.scrollTo(0,0);
  }

  handleCloseArticle = () => {
    this.setState({
      articleTimeout: !this.state.articleTimeout
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: ''
      })
    }, 350)
  }

  handleClickOutside = (event) => {
    const lightbox = document.querySelector('#lightboxBackdrop');
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) &&
      event.target.id !== 'lightboxBackdrop' && (!lightbox || !lightbox.contains(event.target))) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
      }
    }
  }

  render() {
    const { pageContext: { locale } } = this.props;
    
    return (
      <IntlProvider locale={locale}>
        <AppContext.Provider value={this.state}>
          <Layout location={this.props.location} locale={locale}>
            <div className={`body ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
              <div id="wrapper">
                <Header onOpenArticle={this.handleOpenArticle} timeout={this.state.timeout} />
                <Main
                  isArticleVisible={this.state.isArticleVisible}
                  timeout={this.state.timeout}
                  articleTimeout={this.state.articleTimeout}
                  article={this.state.article}
                  onCloseArticle={this.handleCloseArticle}
                  setWrapperRef={this.setWrapperRef}
                />
                <Footer timeout={this.state.timeout} />
              </div>
              <div id="bg">
                <BackgroundSection />
              </div>
            </div>
          </Layout>
        </AppContext.Provider>
      </IntlProvider>
    )
  }
}

export default IndexPage
