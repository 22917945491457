import { ArtEvent } from '../models/event';

export const events = {
    2019: {
        december: {
            events: [
                new ArtEvent(31, 'Szilveszteri mulatság','Hajó')
            ]
        }
    },
    2020: {
        january: {
            events: [
                new ArtEvent(12, 'Jó kis buli','XyZ Étterem'),
                new ArtEvent(20, 'Teszt esemény','ZyX Club')
            ]
        },
        february: {
            events: [
                new ArtEvent(14, 'Valentin napi koncert','XyZ Étterem')
            ]
        }
    }
};
